nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-bg);
  color: white;
  padding: 20px;
}
/* Head */
nav .head {
  font-size: 25px;
  margin-left: 40px;
  cursor: pointer;
}
nav .head .five {
  font-size: 35px;
  color: var(--color-orange);
  display: inline-block;
}
nav .head h4 {
  font-size: 12px;
  color: var(--color-h4);
  letter-spacing: 1px;
}

/* UL */
nav .main-ul {
  display: flex;
  align-items: center;
  margin-right: 100px;
}
nav .main-ul li {
  margin-left: 45px;
}
nav .main-ul li .link {
  color: white;
  font-weight: 700;
}
nav .main-ul li .link:hover {
  color: var(--color-orange);
  transition: all 0.45s;
}
/* ul pages */
.pages {
  position: relative;
}

.lan {
  position: relative;
}
.lan ul {
  display: none;
  color: white;
  background-color: var(--color-bg);
  position: absolute;
  z-index: 10;
}
.lan:hover .lan-ul {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.lan-ul li {
  width: 75px;
  margin-top: 20px;
  margin-left: 0;
}

.lan-ul li .link {
  margin-left: -50px;
  margin-top: 30px;
}
.lan-ul hr {
  border: 0;
  clear: both;
  display: block;
  background-color: rgb(102, 100, 100);
  height: 1px;
  margin-top: 20px;
  width: 85%;
  margin-left: 5px;
}

nav .pages-ul,
.team-ul {
  display: none;
  color: white;
  background-color: var(--color-bg);
}
.pages-ul {
  position: absolute;
  z-index: 10;
}
.main-ul .pages:hover #paUl {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 60px 50px 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
#paUl li {
  width: 200px;
  padding: 5px 30px;
  margin-top: 20px;
  margin-left: 0;
}

#paUl hr {
  border: 0;
  clear: both;
  display: block;
  background-color: rgb(102, 100, 100);
  height: 1px;
  margin-top: 20px;
  width: 100%;
  margin-left: 10px;
}

/* team ul */
.team .team-link {
  position: relative;
  transition: all 0.45s;
}

.team .link:hover .team-ul {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 40px 25px 40px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.team-ul li {
  width: 200px;
  padding: 5px 30px;
  margin-top: 20px;
}

.team-ul li .link {
  margin-left: -50px;
  margin-top: 30px;
}

.team-ul hr {
  border: 0;
  clear: both;
  display: block;
  background-color: rgb(102, 100, 100);
  height: 1px;
  margin-top: 20px;
  width: 85%;
  margin-left: 5px;
}
.icon {
  display: none;
}

@media screen and (max-width: 1024px) {
  nav .main-ul {
    position: absolute;
    top: 95px;
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg);
    width: 100%;
    left: 0;
    align-items: flex-start;
    gap: 2.5rem;
    padding: 20px;
    transition: all 0.45s;

    transform: translateX(-100%);
  }

  nav .main-ul.open {
    transform: translateX(0);
    z-index: 2;
  }
  .icon {
    display: flex;
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin-right: 50px;
  }
  .main-ul-show {
    display: flex;
  }
  nav > .openIcon {
    /* transform: translateX(-40px); */
    background: transparent;
  }
  .main-ul .pages {
    position: relative;
  }
  .main-ul .pages:hover #paUl {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    padding: 0;
    left: -10px;
    font-size: 12px;
    font-weight: 400;
  }

  .lan {
    position: relative;
  }
  .lan:hover .lan-ul {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    padding: 0;
    left: -10px;
    font-size: 12px;
    font-weight: 400;
  }
  .lan-ul li {
    width: 50px;
    margin-left: 0;
  }
  .lan-ul hr {
    display: none;
  }

  #paUl li {
    width: 100px;

    margin-left: 0;
  }
  .team-ul hr {
    border: 0;
    clear: both;
    display: none;
    background-color: rgb(102, 100, 100);
    height: 1px;
    margin-top: 20px;
    width: 85%;
    margin-left: 15px;
  }
  #paUl hr {
    display: none;
  }
  .team .link:hover .team-ul {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    left: -40px;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
  }
  .team-ul li {
    width: 200px;
  }
}

nav .active {
  color: var(--color-orange) !important;
}

@media screen and (max-width: 600px) {
  .head {
    margin-bottom: 0;
  }
  .head h2 {
    font-size: 1.5rem;
    margin-left: -15px;
  }
  nav .head h4 {
    letter-spacing: 1px;
    font-size: small;
    margin-left: -15px;
  }
  nav .main-ul {
    top: 95px;
  }
}
