.home {
  background-image: linear-gradient(
      0deg,
      hsl(218, 26%, 14%, 0.8),
      hsl(218, 26%, 14%, 0.8)
    ),
    url('../../../assets/pic1\ copy.jpg');
  height: 100vh;
  background-size: cover;
}

.home .container {
  display: grid;
  grid-template-columns: 40% 40%;
  gap: 20%;
}
.home-left {
  margin-top: 200px;
  margin-left: 100px;
}

.home-left .top .hi {
  font-size: 100px;
  background: var(--color-orange);
  padding: 30px 44px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-family: Raleway,sans-serif;

}
.home-left .top .five {
  font-size: 100px;
  background: white;
  font-weight: bold;
  color: black;
  padding: 30px 55px;
  font-family: Raleway,sans-serif;

}

.home-left .bottom {
  margin-top: 60px;
}
.home-left .bottom .de {
  font-size: 100px;
  background: hsl(218, 26%, 14%, 0.5);
  color: white;
  padding: 30px 49px;
  text-align: center;
  font-family: Raleway,sans-serif;
  font-weight: bold;
}

.home-left .bottom .ee {
  font-size: 80px;
  background: var(--color-yellow);
  color: white;
  padding: 50px 40px 32px;
  font-weight: bold;
  text-align: center;
  font-family: Raleway,sans-serif;

}

.home-left-text {
  margin-top: 40px;
  margin-left: 10px;
  letter-spacing: 0.4px;

  color: white;
  font-weight: bold;
}

/* home right */

.home-right {
  margin-top: 200px;
  color: white;
  line-height: 1;
}

.home-right h1 {
  font-size: 80px;
  /* margin-left: 180px; */
  margin: 0 auto;
}
.home-right h1 span {
  color: var(--color-orange);
}

.home-right h3 {
  font-size: 80px;
  font-weight: 100;
}

.name2 {
  margin-left: 0px;
}

@media screen and (max-width: 650px) {
  .home .container {
    display: flex;
    flex-wrap: wrap;
  }
  .home {
    margin-bottom: 200px;
    height: fit-content;
  }
  .home-right {
    margin: 50px auto;
  }
  .home-right h1 {
    margin: 0 auto;
    text-align: center;
  }
  .home-right h3 {
    margin: 0 auto;
    text-align: center;
  }
  .home-left {
    margin: 50px auto;
  }
  .home-left-text {
    letter-spacing: 0.4px;
  }
}

@media screen and (max-width: 1024px) {
  .home .container {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 2rem;
  }
  .home-right h3 {
    font-size: 55px;
    font-weight: 100;
}
}

/*

   .home {
      overflow: hidden;
    }
    .home .container {
      grid-template-columns: 1fr;
    }
    .home {
      height: 1000px;
    }
    .home-left {
      margin-bottom: 0;
      margin-left: 0;
    }
    .home-right {
      margin-top: 0;
    }
    .home-right h1 {
      position: absolute;
      left: 50%;
      transform: translateX(-32%);
    }
    .home-right .name1 {
      margin-top: 90px;
    }
    .home-right .name2 {
      position: absolute;
      left: 50%;
      transform: translateX(-74%);
    }
    .home-right {
      margin-left: -90px;
    }
    .home-left-text {
      margin-left: -27px;
    }

    left   .home-left {
      margin-left: -25px;
    }
    .home-left .top .hi {
      font-size: 50px;
      background: var(--color-orange);
      padding: 30px 35px;
      color: white;
      font-weight: bold;
      text-align: center;
    }
    .home-left .top .five {
      font-size: 50px;
      padding: 30px 45px;
    }

    .home-left .bottom {
      margin-top: 58px;
    }
    .home-left .bottom .de {
      font-size: 50px;

      padding: 30px 42.5px;

      font-weight: bold;
    }

    .home-left .bottom .ee {
      font-size: 50px;

      padding: 30px 43.8px;
      font-weight: bold;
    }


  */
