.str {
  margin-top: 100px;
  margin-bottom: 200px;
}
.Aaboat{
  position: relative;
  left: 43%;
  margin: 0 auto;
}
@media screen and (max-width: 600px){
  .Aaboat{
    left: 30%;
  }

}
