.A-video{
    width: 24%;
    margin: 10px auto;

}
.up-Avideo{
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-wrap:wrap;

}
@media screen and (max-width: 1024px) {
    .A-video{
        width: 32%;
    }
    .up-Avideo{
        width: 80%;
    }
    
  }
  
  @media screen and (max-width: 600px) {
    .A-video{
        width: 100%;
    }
    .up-Avideo{
        width: 90%;
    }
    
  }  
