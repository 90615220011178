.webapp-plans {
    margin-bottom: 100px;
  }
  
  .webapp-plans {
    display: flex;
    flex-direction: column;
  }
  
  table {
    border: 2px solid white;
    padding: 10px;
    border-radius: 10px;
    color: white;
    width: 100%;
    margin: 40px auto;
    padding-bottom: 2px;
  }
  .webapp-plans th,
  td {
    border: 1px solid white;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
  }
  
  .buy {
    padding: 15px 50px;
    background-color: transparent;
    border: 2px solid var(--color-orange);
    color: white;
    font-weight: bold;
    letter-spacing: 2;
    cursor: pointer;
    transition: var(--transition);
    margin: 30px auto;
    position: absolute;
    left: 50%;
    transform: translate(-45%);
  }
  .buy:hover {
    background: var(--color-orange);
  }
  .btn {
    color: white;
    background-color: transparent;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: var(--transition);
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--color-orange);
  }
  .btn:hover {
    opacity: 0.8;
    background-color: var(--color-orange);
  }
  .whatsappIcon{
    position: fixed;
    z-index: 10;
    width: 100px;
    height: 100px;
    right: 25px ;
    top: 86%;
    cursor: pointer;

  }


  .general-background-image {
    background-image: linear-gradient( 0deg, hsl(218, 26%, 14%, 0.8),
     hsl(218, 26%, 14%, 0.8) ),
      url('../../../assets/PRODUCTS\ copy.jpg');
    height: 65vh;
    background-size: cover;
}
  @media screen and (max-width: 600px) {
    .found-header {
      font-size: 28px;
      font-size: 15px;
    }
  
    .webapp-plans th,
    td {
      padding: 5px;
      font-size: 10px;
    }
    .plans {
      margin-bottom: 400px;
    }
    .whatsappIcon{
      right: -5px;
      width: 80px;
      height: 80px;
      top: 87.5%;
    }
  }
  