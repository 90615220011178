.btn1{
    width: 90px;
    height: 30px;
    background-color: brown;
    border-radius: 15px;
    margin: 3px;
    transition: var(--transition);
    cursor: pointer;
    color: aliceblue;

}
.btn1:hover{
    background-color: transparent;
    border: 1px solid var(--color-orange);

}
.btn2{
    width: 100px;
    height: 30px;
    color: white;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
    transition: var(--transition);
    border-radius: 15px;
    border: 1px solid var(--color-orange);
    margin: 3px;

}
.btn2:hover{
    background-color: #52adb8;
}


