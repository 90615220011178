.btn4{
    height: 35px;
}
.total{
 /*   display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;*/
    background: var(--color-bg);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 20px;
    color: aliceblue;
    width: 50%;
    margin: auto;
    text-align: center;
    width: 60%;
    
}
.total h3{
    margin-top: 10px;
    text-align: center;
    transition: all;
}
.up-total{
    display: grid;
    grid-template-columns: 50% 50% ;
    margin-bottom: 20px;
}
.link h3:hover{
    color: antiquewhite;

}
.btn5{
    margin-bottom: 20px;
    width: 80%;
}




@media screen and (max-width: 600px) {
    .total{
        width: 90%;
    }
    .total h2{
        font-size: 15px;
    }
    .total h3{
        font-size: 15px;
    }
  }