.str {
  margin-bottom: 100px;
}

.strategy-background {
  background-image: linear-gradient(
      0deg,
      hsl(218, 26%, 14%, 0.96),
      hsl(218, 26%, 14%, 0.96)
    ),
    url('../../../assets/Businessman-Transparent.png');
  height: 100vh;
  position: relative;
}

.strategy-header {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-45%);
  color: white;
  font-size: 40px;
}
.strategy-header span {
  color: var(--color-orange);
}

.description {
  display: flex;
  justify-content: center;
  gap: .5rem;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-45%, -80%);
  color: white;
  font-size: 30px;
  width: fit-content;
  flex-direction: column;
  background: var(--color-bg-black);
  padding: 50px;
  border-radius: 20px;
}

.description p {
  font-weight: 400;
  width: 600px;
  font-size: 22px;
  line-height: 2;
  text-align: center;
}
.description h5 {
  text-align: center;
  color: chocolate;
}

.slider-point {
  display: flex;
  gap: 5rem;
  margin: 0 auto;
}

.point {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid var(--color-orange);
  cursor: pointer;
}
.point:hover {
  background: var(--color-orange);
}
@media screen and (max-width: 1024px) {
  .description {
    top: 80%;
    left: 48%;
  }
  .description p {
    font-weight: 400;
    width: 406px;
    font-size: 20px;
    line-height: 2;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .strategy-header {
    font-size: 30px;
  }
  .description {
    padding: 20px;
    top: 67%;
    left: 48%;
  }
  .description p {
    font-size: 20px;
    line-height: 1.5;
    width: 330px;
  }
  .description h5 {
    font-size: 22px;
  }
  .slider-point {
    gap: 1rem;
  }
}
@media screen and (max-width: 420px) {
  .description p {
    font-size: 20px;
    line-height: 1.5;
    width: 250px;
}
.description {
    top: 73%;
  }
}
