/* form{
    width: 30%;
    margin: 30px;
    text-align: center;
}
form label{

    font-size: x-large;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    letter-spacing: 2px;
    

}
form input{
    width: 100%;
    height: 50px;
    border-radius: 20px;
    text-align: center;
}
form button{
    width: 100%;
    height: 50px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;

}



.home-contact-container {
    display: flex;
    margin-bottom: 100px;
  }
  
  
  form div {
    display: flex;
    gap: 1rem;
    font-weight: bold;
  }
 
  
  
  
  form label span {
    font-weight: bold;
  }
  form textarea {
    font-weight: 500;
  
    font-size: 15px;
    padding-left: 20px;
    padding-top: 20px;
  }
  
  .send {
    background-color: transparent;
    border: 2px solid var(--color-yellow);
    padding: 15px 50px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 15px;
    margin-top: 10px;
    cursor: pointer;
    transition: var(--transition);
    width: 30%;
  }
  
  .send:hover {
    background-color: var(--color-yellow);
  }
  
  .home-contact-image img {
    width: 550px;
    position: relative;
    left: -30px;
  }
  
  @media screen and (max-width: 1024px) {
    .home-contact-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
    }
    .home-contact-image img {
      left: 0px;
    }
    .send {
      margin: 0 auto;
    }
  }
  
  @media screen and (max-width: 600px) {
    .home-contact-container {
      margin-bottom: 100px;
    }
    form {
      margin-left: 0;
      text-align: center;
    }
    .home-contact-image img {
      width: 300px;
      height: 400px;
    }
    .send {
      width: 50%;
      text-align: center;
    }
    form input,
    form textarea {
      font-size: 12px;
    }
  }
   */

   .home-contact-container {
    display: flex;
    margin-bottom: 100px;
  }
  
  form {
    /* width: 80%; */
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-left: 50px;
    z-index: 2;
    width: 40%;
  }
  form div {
    display: flex;
    gap: 1rem;
    font-weight: bold;
  }
  input {
    padding: 30px;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    height: 50px;
    border-radius: 20px;
    text-align: center;
  }
  
  form label {
   /* font-size: 60px;*/
    color: white;
    font-weight: 200;

    font-size: x-large;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    
  }
  
  form label span {
    font-weight: bold;
  }
  form textarea {
    font-weight: 500;
  
    font-size: 15px;
    padding-left: 20px;
    padding-top: 20px;
  }
  
  .send {
    background-color: transparent;
    border: 2px solid var(--color-yellow);
    padding: 15px 50px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 15px;
    margin-top: 10px;
    cursor: pointer;
    transition: var(--transition);
    width: 30%;
  }

  form button{
    width: 100%;
    height: 50px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;

}
  
  .send:hover {
    background-color: var(--color-yellow);
  }
  
  .home-contact-image img {
    width: 550px;
    position: relative;
    left: -30px;
  }
  
  @media screen and (max-width: 1024px) {
    .home-contact-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
    }
    .home-contact-image img {
      left: 0px;
    }
    .send {
      margin: 0 auto;
    }
  }
  
  @media screen and (max-width: 600px) {
    .home-contact-container {
      margin-bottom: 100px;
    }
    form {
      margin-left: 0;
      text-align: center;
        width: 80%; 
       display: flex;
       flex-direction: column;
       gap: 3rem;
       margin-left: 50px;
       z-index: 2;
    }
  
    .home-contact-image img {
      width: 300px;
      height: 400px;
    }
    .send {
      width: 50%;
      text-align: center;
    }
    form input,
    form textarea {
      font-size: 12px;
    }
  }
  
