.team-work {
  margin-bottom: 100px;
 /* height: 650px;*/
  overflow-y: hidden;
}

.team-work-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.team-work-persons {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  gap: 3rem;
  font-size: 50px;
  margin-top: 100px;
}
.team-work-images{
  margin: 80px auto;
}

.team-link {
  color: white;
  transition: var(--transition);
}
.team-link:hover {
  color: var(--color-yellow);
}

.team-link span {
  font-size: 43px;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .team-work-persons {
    font-size: 30px;
  }
  .team-link span {
    font-size: 30px;
  }
  .team-work {
    height: 1000px;
  }
}

@media screen and (max-width: 600px) {
  .team-work-persons {
    font-size: 25px;
    position: relative;
    
    line-height: 1;
    gap: 1rem;
  }
  .team-link span {
    font-size: 25px;
  }
  .team-work-images {
    margin-right: 100px;
  }
  img {
    width: 250px;
  }
  .team-work-container {
    justify-content: center;
  }
  .team-work {
    height: 600px;
  }
}
